exports.components = {
  "component---src-nodes-corso-js": () => import("./../../../src/nodes/Corso.js" /* webpackChunkName: "component---src-nodes-corso-js" */),
  "component---src-nodes-sanity-page-js": () => import("./../../../src/nodes/SanityPage.js" /* webpackChunkName: "component---src-nodes-sanity-page-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contattaci-ok-tsx": () => import("./../../../src/pages/contattaci-ok.tsx" /* webpackChunkName: "component---src-pages-contattaci-ok-tsx" */),
  "component---src-pages-contattaci-tsx": () => import("./../../../src/pages/contattaci.tsx" /* webpackChunkName: "component---src-pages-contattaci-tsx" */),
  "component---src-pages-didattica-tsx": () => import("./../../../src/pages/didattica.tsx" /* webpackChunkName: "component---src-pages-didattica-tsx" */),
  "component---src-pages-dove-siamo-tsx": () => import("./../../../src/pages/dove-siamo.tsx" /* webpackChunkName: "component---src-pages-dove-siamo-tsx" */),
  "component---src-pages-eventi-tsx": () => import("./../../../src/pages/eventi.tsx" /* webpackChunkName: "component---src-pages-eventi-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */)
}

